const defaultValues = {
  REACT_APP_URL_MX: 'devaxa-asistenciaviaje.com.mx',
  REACT_APP_URL_AR: 'devaxa-asistenciaviaje.com.ar',
  REACT_APP_URL_CO: 'devaxa-asistenciaviaje.com',
  REACT_APP_URL_US: 'devaxatravelinsurance.com',
  REACT_APP_PUBLIC_CLIENT_ID: 'abc12666',
  REACT_APP_PUBLIC_SECRET_ID: '29ced501-0aa7-4c17-9f5e-794a63742acd',
  REACT_APP_PRISMIC_URL: 'https://b2c-axa.cdn.prismic.io/api/v2',
  REACT_APP_PRISMIC_SECRET:
    'MC5Yb2NmdnhBQUFDTUFwSXEw.77-977-977-977-9InLvv70QGe-_ve-_ve-_ve-_vWFC77-977-977-9aO-_ve-_ve-_ve-_vTnvv70877-9c0d777-9DQ',
  REACT_APP_INSIGHTS_INSTRUMENTATIONKEY: '8a394fe1-9453-4bb4-9b4b-34b3d3f32977'
};

const getconfig = () => {
  const config = {};
  Object.keys(defaultValues).forEach(key => {
    if (!process.env[key]) {
      config[key] = defaultValues[key];
      return;
    }
    const includesKey = process.env[key].includes(key);
    config[key] = includesKey ? defaultValues[key] : process.env[key];
  });
  return config;
};

export const config = getconfig();
