import React, { Suspense } from 'react';
import Route from './Route';
import LoadingCircle from '../components/LoadingCircle/LoadingCircle';
import { prefixAr, prefixCo, prefixMx, prefixUs } from '../location/urls';

const PrivacyPolicy = React.lazy(() =>
  import('../pages/PrivacyPolicyPage/PrivacyPolicyPage')
);
const PrivacyPolicyPage = () => {
  return (
    <Suspense fallback={<LoadingCircle />}>
      <PrivacyPolicy />
    </Suspense>
  );
};

const CookiePolicy = React.lazy(() =>
  import('../pages/CookiePolicyPage/CookiePolicyPage')
);
const CookiePolicyPage = () => {
  return (
    <Suspense fallback={<LoadingCircle />}>
      <CookiePolicy />
    </Suspense>
  );
};

const ARCookiePolicy = React.lazy(() =>
  import('../pages/CookiePolicyPage/ARCookiePolicyPage')
);
const ARCookiePolicyPage = () => {
  return (
    <Suspense fallback={<LoadingCircle />}>
      <ARCookiePolicy />
    </Suspense>
  );
};

const COCookiePolicy = React.lazy(() =>
  import('../pages/CookiePolicyPage/COCookiePolicyPage')
);
const COCookiePolicyPage = () => {
  return (
    <Suspense fallback={<LoadingCircle />}>
      <COCookiePolicy />
    </Suspense>
  );
};

const TermsAndConditions = React.lazy(() =>
 import('../pages/TermsAndConditions/TermsAndConditions')
);
const TermsAndConditionsPage = () =>{
  return(
    <Suspense fallback={<LoadingCircle/>}>
      <TermsAndConditions />
    </Suspense>
  )
}
const TerminosLegales = React.lazy(() =>
  import('../pages/TerminosLegalesPage/TerminosLegalesPage')
);
const TerminosLegalesPage = () => {
  return (
    <Suspense fallback={<LoadingCircle />}>
      <TerminosLegales />
    </Suspense>
  );
};

const AsistenciaPage = React.lazy(() =>
  import('../pages/Asistencia/Asistencia')
);
const Asistencia = () => (
  <Suspense fallback={<LoadingCircle />}>
    <AsistenciaPage />
  </Suspense>
);

const ContactPage = React.lazy(() => import('../pages/Contact/Contact'));
const Contact = () => (
  <Suspense fallback={<LoadingCircle />}>
    <ContactPage />
  </Suspense>
);

const FaqPage = React.lazy(() => import('../pages/Faq/Faq'));
const Faq = () => (
  <Suspense fallback={<LoadingCircle />}>
    <FaqPage />
  </Suspense>
);

const Legales_PromocionesPage = React.lazy(() => import('../pages/LegalesPromociones/Legales_Promociones'));
const Legales_Promociones = () => (
  <Suspense fallback={<LoadingCircle />}>
    <Legales_PromocionesPage />
  </Suspense>
);
const PlanesPage = React.lazy(() => import('../pages/Planes/Planes'));
const Planes = () => (
  <Suspense fallback={<LoadingCircle />}>
    <PlanesPage />
  </Suspense>
);

const PlanesDetailPage = React.lazy(() =>
  import('../pages/Planes/PlanesDetail/PlanesDetail')
);
const PlanesDetail = () => (
  <Suspense fallback={<LoadingCircle />}>
    <PlanesDetailPage />
  </Suspense>
);

const NosotrosPage = React.lazy(() => import('../pages/Nosotros/Nosotros'));
const Nosotros = () => (
  <Suspense fallback={<LoadingCircle />}>
    <NosotrosPage />
  </Suspense>
);

const AboutAxaPage = React.lazy(() => import('../pages/AboutAxa/AboutAxa'));
const AboutAxa = () => (
  <Suspense fallback={<LoadingCircle />}>
    <AboutAxaPage />
  </Suspense>
);

const TermsAndConditionsPDFPage = React.lazy(() =>
  import('../pages/TermsAndConditionsPDF/TermsAndConditionsPDF')
);
const TermsAndConditionsPDF = () => (
  <Suspense fallback={<LoadingCircle />}>
    <TermsAndConditionsPDFPage />
  </Suspense>
);

const PromocionesPage = React.lazy(() =>
  import('../pages/Promociones/Promociones')
);
const Promociones = () => (
  <Suspense fallback={<LoadingCircle />}>
    <PromocionesPage />
  </Suspense>
);

const AMEXBeneficiosPage = React.lazy(() =>
  import('../pages/AMEXBeneficios/AMEXBeneficios')
);
const AMEXBeneficios = () => (
  <Suspense fallback={<LoadingCircle />}>
    <AMEXBeneficiosPage />
  </Suspense>
);

const CasoEmergenciaPage = React.lazy(() =>
  import('../pages/CasoEmergencia/CasoEmergencia')
);
const CasoEmergencia = () => (
  <Suspense fallback={<LoadingCircle />}>
    <CasoEmergenciaPage />
  </Suspense>
);

const NotificarRegistroPage = React.lazy(() =>
  import('../pages/NotificarRegistro/NotificarRegistro')
);
const NotificarRegistro = () => (
  <Suspense fallback={<LoadingCircle />}>
    <NotificarRegistroPage />
  </Suspense>
);

const StaticPageContentPage = React.lazy(() =>
  import('../pages/StaticPageContent/StaticPageContent')
);
const StaticPageContent = ({ ...props }) => (
  <Suspense fallback={<LoadingCircle />}>
    <StaticPageContentPage {...props} />
  </Suspense>
);

const FileClaimPage = React.lazy(() => import('../pages/FileClaim/FileClaim'));
const FileClaim = () => (
  <Suspense fallback={<LoadingCircle />}>
    <FileClaimPage />
  </Suspense>
);

const ContactanosPage = React.lazy(() =>
  import('../pages/Contactanos/Contactanos')
);
const Contactanos = () => (
  <Suspense fallback={<LoadingCircle />}>
    <ContactanosPage />
  </Suspense>
);

const ComparePlansPage = React.lazy(() =>
  import('../pages/ComparePlans/ComparePlans')
);
const ComparePlans = () => (
  <Suspense fallback={<LoadingCircle />}>
    <ComparePlansPage />
  </Suspense>
);

const BenefitsPage = React.lazy(() => import('../pages/Benefits/Benefits'));
const Benefits = () => (
  <Suspense fallback={<LoadingCircle />}>
    <BenefitsPage />
  </Suspense>
);

const PhonePage = React.lazy(() => import('../pages/Phone/Phone'));
const Phone = () => (
  <Suspense fallback={<LoadingCircle />}>
    <PhonePage />
  </Suspense>
);

const POMT = React.lazy(() =>
  import('../pages/PeaceOfMindTerms/PeaceOfMindTerms')
);
const PeaceOfMindTerms = () => (
  <Suspense fallback={<LoadingCircle />}>
    <POMT />
  </Suspense>
);



const CotizarAsist = React.lazy(() =>
  import('../pages/CotizarAsistencia/CotizarAsistencia')
);
const CotizarAsistencia = () => (
  <Suspense fallback={<LoadingCircle />}>
    <CotizarAsist />
  </Suspense>
);

export const EspecificRoutes = {
  [prefixAr]: [
    <Route
      key={11}
      path="/contacta"
      component={() => <StaticPageContent type="contactanos" />}
    />,
    <Route
      key={12}
      path="/cookies"
      component={() => <StaticPageContent type="cookies" />}
    />,
    <Route key={13} path="/en-caso-de-emergencia" component={CasoEmergencia} />,
    <Route key={14} path="/faq" component={Faq} />,
    <Route key={15} path="/cotizar-asistencia" component={CotizarAsistencia} />,
    <Route
      key={505}
      path="/cotizar-asistencia/planes"
      component={CotizarAsistencia}
    />,
    <Route
      key={507}
      path="/cotizar-asistencia/pago"
      component={CotizarAsistencia}
    />,
    <Route
      key={508}
      path="/cotizar-asistencia/gracias"
      component={CotizarAsistencia}
    />,
    <Route
      key={16}
      path="/notificar-un-reintegro"
      component={NotificarRegistro}
    />,
    <Route
      key={17}
      path="/privacidad"
      component={() => <StaticPageContent type="policy" />}
    />,
    <Route key={17} path="/beneficios" component={() => <Benefits />} />,
    <Route
      key="arrepentimiento"
      path="/arrepentimiento"
      component={() => <StaticPageContent type="arrepentimiento" />}
    />,
    <Route
      key={941}
      path="/terms-conditions-pdf/:name"
      component={TermsAndConditionsPDF}
    />,
    <Route
      key={444}
      path="/politica-de-cookies"
      component={ARCookiePolicyPage}
    />,
    <Route key={666} path="/terminos-legales" component={TerminosLegalesPage} />,
    <Route key={667} path="/legales_promociones" component={Legales_Promociones} />
  ],
  [prefixCo]: [
    <Route
      key={21}
      path="/aviso-de-privacidad"
      component={() => <StaticPageContent type="policy" />}
    />,
    <Route key={22} path="/contactanos" component={Contactanos} />,
    <Route key={23} path="/cotizar-asistencia" component={CotizarAsistencia} />,
    <Route key={24} path="/faqs" component={Faq} />,
    <Route key={25} path="/nosotros" component={Nosotros} />,
    <Route key={26} path="/planes/:code_seo" component={PlanesDetail} />,
    <Route key={27} path="/planes" component={Planes} />,
    <Route key={28} path="/solicitar-asistencia" component={Asistencia} />,
    <Route key={29} path="/phone" component={Phone} />,
    <Route
      key={511}
      path="/cotizar-asistencia/planes"
      component={CotizarAsistencia}
    />,
    <Route
      key={512}
      path="/cotizar-asistencia/pago"
      component={CotizarAsistencia}
    />,
    <Route
      key={513}
      path="/cotizar-asistencia/gracias"
      component={CotizarAsistencia}
    />,
    
    <Route
      key={941}
      path="/terms-conditions-pdf/:name"
      component={TermsAndConditionsPDF}
    />,
     <Route
     path="/terms-and-conditions"
     component={TermsAndConditionsPage}/>,
    <Route
      key={900}
      path="/politica-de-cookies"
      component={COCookiePolicyPage}
    />,
    <Route key={888} path="/terminos-legales" component={TerminosLegalesPage} />
  ],
  [prefixMx]: [
    <Route
      key={31}
      path="/avisodeprivacidad"
      component={PrivacyPolicyPage}
    />,
    <Route key={32} path="/asistencia" component={Asistencia} />,
    <Route key={33} path="/contactanos" component={Contactanos} />,
    <Route key={34} path="/cotizar-asistencia" component={CotizarAsistencia} />,
    <Route key={35} path="/faq" component={Faq} />,
    <Route key={36} path="/nosotros" component={Nosotros} />,
    <Route key={37} path="/mis-planes/:code_seo" component={PlanesDetail} />,
    <Route key={38} path="/mis-planes" component={Planes} />,
    <Route key={39} path="/promociones" component={Promociones} />,
    <Route key={40} path="/AMEXBeneficios" component={AMEXBeneficios} />,
    <Route
      key={941}
      path="/terms-conditions-pdf/:name"
      component={TermsAndConditionsPDF}
    />,
    <Route
      key={516}
      path="/cotizar-asistencia/planes"
      component={CotizarAsistencia}
    />,
    <Route
      key={517}
      path="/cotizar-asistencia/pago"
      component={CotizarAsistencia}
    />,
    <Route
      key={518}
      path="/cotizar-asistencia/gracias"
      component={CotizarAsistencia}
    />,
    <Route
      key={520}
      path="/politica-de-cookies"
      component={CookiePolicyPage}
    />,
    <Route
      key={1022}
      path="/terminos-legales"
      component={TerminosLegalesPage}
    />,
    <Route
      key={519}
      path="/travelprotection"
      component={() => {
        window.location.href =
          'https://prismic-io.s3.amazonaws.com/b2c-axa/a83a0ffb-6aba-4d87-a373-1dc5cc6bd59f_Comunicaci%C3%B3n+Campa%C3%B1a+Pruebas+Covid-19+Amex+Mex+vInternet+20210921.pdf';
        return null;
      }}
    />
  ],
  [prefixUs]: [
    <Route key={41} path="/about-axa" component={AboutAxa} />,
    <Route key={50} path="/compare-plans" component={ComparePlans} />,
    <Route key={42} path="/contact-us" component={Contact} />,
    <Route
      key={43}
      path="/cookies"
      component={() => <StaticPageContent type="cookies" />}
    />,
    <Route key={44} path="/file-a-claim" component={FileClaim} />,
    <Route key={45} path="/faqs" component={Faq} />,
    <Route key={46} path="/our-plans/:code_seo" component={PlanesDetail} />,
    <Route key={47} path="/our-plans" component={Planes} />,
    <Route key={48} path="/get-a-quote-now" component={CotizarAsistencia} />,
    <Route
      key={501}
      path="/get-a-quote-now/planes"
      component={CotizarAsistencia}
    />,
    <Route
      key={502}
      path="/get-a-quote-now/pago"
      component={CotizarAsistencia}
    />,
    <Route
      key={503}
      path="/get-a-quote-now/gracias"
      component={CotizarAsistencia}
    />,
    <Route
      key={49}
      path="/privacy-policy"
      component={() => <StaticPageContent type="policy" />}
    />,
    <Route
      key={410}
      path="/terms-conditions"
      component={() => <StaticPageContent type="terms" />}
    />,
    <Route
      key={413}
      path="/nw-disclosure"
      component={() => <StaticPageContent type="disclosure" />}
    />,
    <Route
      key={411}
      path="/why-buy"
      component={() => <StaticPageContent type="whyBuy" />}
    />,
    <Route key={412} path="/phone-contact" component={Contactanos} />,
    <Route key={611} path="/clubmedterms" component={PeaceOfMindTerms} />
  ]
};