/* eslint-disable */
import './assets/css/bootstrap.min.css';
import React, {Suspense} from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import './styles/styles.scss';
import styles from './App.module.scss';
import Router from './routes/Router';
import Location from './components/Location/Location';
import { IntlParent } from './intl';
import StateContextParent from './components/StateContextParent/StateContextParent';
import GTM from './components/GTM/GTM';
import GTMPixels from './components/GTMPixels/GTMPixels';
import HtmlContextParent from './components/HtmlContextParent/HtmlContextParent';
import LoadingCircle from "./components/LoadingCircle/LoadingCircle";

const Header = React.lazy(() => import('./components/Header/Header'));
const Footer = React.lazy(() => import('./components/Footer/Footer'));

function App() {
  return (
    <div className={styles.app}>
      <ToastProvider placement="bottom-center">
        <BrowserRouter>
          <Location>
            <IntlParent>
              <StateContextParent>
                <GTM />
                <GTMPixels />
                <HtmlContextParent>
                  <div className={styles.boxApp}>
                    <Suspense fallback={<LoadingCircle />}>
                      <Header />
                    </Suspense>
                    <div className={styles.body}>
                      <Router />
                    </div>
                    <Suspense fallback={<LoadingCircle />}>
                      <Footer />
                    </Suspense>
                  </div>
                </HtmlContextParent>
              </StateContextParent>
            </IntlParent>
          </Location>
        </BrowserRouter>
      </ToastProvider>
    </div>
  );
}

export default App;
